/* eslint-disable */
import React, { useContext, useEffect, useCallback, useState, useReducer, useRef } from 'react'
import { UserContext } from 'account'
import { LayoutContext, app } from 'common/ducks'
import { PieChart, Pie, Cell, Label, ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Bar, BarChart } from 'recharts';
import styled from 'styled-components'
import useApi from 'common/hooks/useApi'
import useAppTitle from 'common/hooks/useAppTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Fab from '@material-ui/core/Fab';
import { ActivityLogContext, INITIAL_STATE, reducer } from 'activity/ducks'
import DataTable from 'activity/components/DataTable'
import logger from 'use-reducer-logger'
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import CalHeatmap from 'cal-heatmap'
import 'cal-heatmap/cal-heatmap.css'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const cal = new CalHeatmap()
const loadCal = new CalHeatmap()
const rpeCal = new CalHeatmap()

function AthleteReport() {
    const user = useContext(UserContext)
    const routeParams = useParams()

    const AthleteIconDiv = styled.div`
        padding-top: 25px;
        padding-left: 15px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;
    `;

    const thresholds = [
        {
            "name": "Ready",
            "value": 100
        },
        {
            "name": "Moderate Risk",
            "value": 70
        },
        {
            "name": "At Risk",
            "value": 50
        },
    ];
    const today = new Date();
    const startDate = new Date()
    startDate.setDate(startDate.getDate()-180)
    const [state, dispatch] = useReducer(logger(reducer), INITIAL_STATE)
    const { setAppLoading, setDrawerOptions } = useContext(LayoutContext)
//    let viewDataAs = useSelector((globalState) => globalState.app.viewDataAs)
    const [cardData, setCardData] = useState({calories: '', heartRate: '', sleep: '', acwr: '', acwrMileage: '', momentumScore: '', heatmap: [], loadHeatmap: [], startDate: '', endDate: '', rpe: []});
    const [showAcwrInfo, setShowAcwrInfo] = useState(false)
    const [showRHRInfo, setShowRHRInfo] = useState(false)
    const [showSleepInfo, setShowSleepInfo] = useState(false)
    const [showCalBurnedInfo, setShowCalBurnedInfo] = useState(false)
    const [report, setReport] = useState('')
    const [athleteList, setAthleteList] = useState([])
    const [athlete, setAthlete] = useState()

    const dispatchData = useDispatch()

    useEffect(() => {
      if(routeParams.id !== 'None') {
        setAthlete(routeParams.id)
        dispatchData(app.actions.changeViewDataAs(routeParams.id))
      }
    }, [])

    const [{ loading: infoLoading }, info] = useApi('metrics/athlete_summary/', { manual: true, user: athlete })
    const [{ loading: listLoading }, list ] = useApi({url: 'builder/onerepmax/athlete_list/'}, {manual: true})
    const [{ loading: sendLoading }, sendReport] = useApi(
        {
          method: 'POST',
          url: 'trainers/athlete_feedback/',
        },
        { manual: true }
      )

    const fetchData = useCallback(
        async () => {
            const response = await info({
                params: {
                    user: athlete,
                },
            })
            if (response?.data){
                cal.paint({
                    range: 2,
                    domain: {type: 'month', gutter: 30},
                    date: { start: moment().subtract(1, 'month').toDate(), max: new Date() },
                    itemSelector: '#activity-heatmap',
                    data: {source: response?.data?.heatmap, defaultValue: 0, x: 'date', y: 'value'},
                    subDomain: {type: 'xDay', width: 25, height: 25, gutter: 4, label: 'D'},
                    scale: {
                        color: {
                            // Try some values: Purples, Blues, Turbo, Magma, etc ...
                            range: ['#EDEDED', '#0B545E'],
                            interpolate: 'hsl',
                            type: 'sqrt',
                            domain: [0, 30]
                        },
                    }
                });
                loadCal.paint({
                    range: 2,
                    domain: {type: 'month', gutter: 30},
                    date: { start: moment().subtract(1, 'month').toDate(), max: new Date() },
                    itemSelector: '#load-heatmap',
                    data: {source: response?.data?.loadHeatmap, defaultValue: 0, x: 'date', y: 'value'},
                    subDomain: {type: 'xDay', width: 25, height: 25, gutter: 4, label: 'D'},
                    scale: {
                        color: {
                            // Try some values: Purples, Blues, Turbo, Magma, etc ...
                            range: ['#EDEDED', '#0B545E'],
                            interpolate: 'hsl',
                            type: 'sqrt',
                            domain: [0, 3000]
                        },
                    }
                });
                rpeCal.paint({
                    range: 2,
                    domain: {type: 'month', gutter: 30},
                    date: { start: moment().subtract(1, 'month').toDate(), max: new Date() },
                    itemSelector: '#rpe-heatmap',
                    data: {source: response?.data?.rpe, defaultValue: 0, x: 'date', y: 'value'},
                    subDomain: {type: 'xDay', width: 25, height: 25, gutter: 4, label: 'D'},
                    scale: {
                        color: {
                            // Try some values: Purples, Blues, Turbo, Magma, etc ...
                            range: ['#EDEDED', '#0B545E'],
                            interpolate: 'hsl',
                            type: 'sqrt',
                            domain: [0, 5]
                        },
                    }
                })
                setCardData(response?.data)
            }
        },
        [ info, athlete]
    )

    const fetchList = useCallback(
      async () => {
        const response = await list()
        setAthleteList(response?.data)
      },
      [ list]
    )

    useEffect(() => {
      fetchData()
    }, [athlete])

    useEffect(() => {
      fetchList()
      fetchData()
    }, [fetchList, list])

    useEffect(() => {
      setAppLoading(listLoading || sendLoading || infoLoading)
    }, [listLoading, sendLoading, setAppLoading, infoLoading])

    useAppTitle('Athlete Report')

    useEffect(() => {
        setDrawerOptions({
            onAdd: null,
            showProgram: true,
            showAthletes: false,
        })
    }, [setDrawerOptions])

    const handleChange = (event) => {
      setReport(event.target.value);
    }

    async function saveReport() {

      const body = {
        user: athlete ?? user.email,
        trainer: user.id,
        comments: report,
        dateAdded: today,
      }

      await sendReport({
        data: body,
      })
      alert('Report Sent')
    }

    const handleAthleteChange = (event: SelectChangeEvent) => {
        setAthlete(event.target.value);
        dispatchData(app.actions.changeViewDataAs(event.target.value))
    }

    const columns = [
    {
      name: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'Day',
      options: {
        customBodyRender: (value) => moment(value).format('L'),
        display: true,
        searchable: true,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Name',
      options: {
        display: true,
        filterType: 'multiselect',
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Sets',
      options: {
        display: true,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Weight (lbs)',
      options: {
        display: true,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Reps',
      options: {
        display: true,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Distance (meter)',
      options: {
        display: false,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )

      },
    },
    {
      name: 'Work Time (seconds)',
      options: {
        display: false,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Rest Time (seconds)',
      options: {
        display: false,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'RIR',
      options: {
        display: false,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Calories Burned',
      options: {
        display: false,
        filter: false,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
    {
      name: 'Program Info',
      options: {
        display: false,
        filter: false,
        customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
      },
    },
  ]

    return (
    <ActivityLogContext.Provider value={{ state, dispatch }}>
        <AthleteReport.Stylesheet>
            <AthleteIconDiv>
                <FormControl variant='outlined' style={{paddingRight: '20px', minWidth: '150px', width: '25%'}} >
                <InputLabel >Athlete</InputLabel>
                    <Select
                      value={athlete}
                      onChange={handleAthleteChange}
                    >
                      {athleteList?.map((row) => (
                              <MenuItem value={row?.email} key={row?.email}>{row?.name}</MenuItem>
                          ))}
                    </Select>
                </FormControl>
                <Fab variant="extended" color='primary' style={{ position: 'absolute', right: 30}}
                onClick={saveReport}>
                <SaveOutlinedIcon sx={{ mr: 1 }} />
                  Send Report
                </Fab>
            </AthleteIconDiv>


            <Grid container spacing={0} style={{height: '350px'}}>
                <Grid item xs={3}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className="section mt-2">
                              <div className="card" style={{minHeight: '100px'}}>
                                  <div className="card-header">
                                    <span className="card-subtitle">Program Info</span>
                                  </div>
                                  <div className="card-body">
                                      <h5 className="card-title" style={{marginLeft: 20}}>
                                          {cardData?.programInfo || '-'}
                                      </h5>
                                  </div>
                              </div>
                              </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="section mt-2">
                              <div className="card" style={{minHeight: '220px'}}>
                                  <div className="card-header">
                                    <span className="card-subtitle">1RM Table</span>
                                  </div>
                                  <div className="card-body">
                                  <TableContainer component={Paper} style={{height: 150, width: '20vw', margin: '0px', boxShadow: 'inherit'}}>
                                    <Table>
                                        <TableBody>
                                        <TableRow
                                              key='header'
                                              style={{backgroundColor: 'white'}}
                                            >
                                              <TableCell align="left" style={{display: 'flex', marginLeft: 20, width: 200}}><h3>Activity</h3></TableCell>
                                              <TableCell align="left" style={{ marginLeft: 20, width: 100}}><h3>Estimated</h3></TableCell>
                                              <TableCell align="left" style={{ marginLeft: 20, width: 100}}><h3>Historical</h3></TableCell>
                                            </TableRow>
                                          {cardData?.est1Rm?.map((row) => (
                                            <TableRow
                                              key={row?.name}
                                              style={{backgroundColor: 'white'}}
                                            >
                                              <TableCell align="left" style={{display: 'flex', marginLeft: 20, width: 200}}><h3>{row?.name}</h3></TableCell>
                                              <TableCell align="left" style={{ marginLeft: 20, width: 150}}><h3>{row?.value?.est}</h3></TableCell>
                                              <TableCell align="left" style={{ marginLeft: 40, width: 150}}><h3>{row?.value?.hist}</h3></TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                  </div>
                              </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} style={{minHeight: '230px'}}>

                          <ResponsiveContainer width='100%' height="100%">
                            <PieChart>
                              <Pie startAngle={200} endAngle={-20} data={thresholds} dataKey="value" innerRadius="90%" outerRadius="100%" >
                                <Cell value={50} fill="#FF7262" />
                                <Cell value={20} fill="#FFF5E5" />
                                <Cell value={30} fill="#C7E8ED" />
                              </Pie>
                              <Pie startAngle={200} endAngle={-20} dataKey="value" innerRadius="65%" outerRadius="89%">
                                <Cell value={cardData?.momentumScore} fill="#0B545E" />
                                <Cell value={100-(cardData?.momentumScore || 0)} fill="white" />
                                 <Label value={cardData?.momentumScore} position="center" style={{ textAnchor: 'middle', fontSize: '400%',}}/>
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="card" style={{minHeight: '20px'}}>
                                  <div className="card-header">
                                    <span className="card-subtitle">Week Over Week Load Comparison Data</span>
                                  </div>
                                  <div className="card-body">
                                      <h5 className="card-title" style={{marginLeft: 20}}>
                                          Strength:     {cardData?.wowLoad || '-'} &nbsp; &nbsp; &nbsp; Distance:     {cardData?.wowDistance || '-'}
                                      </h5>
                                  </div>
                              </div>
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={3}>
                  <div className="section mt-2">
                    <div className="card" style={{height: '330px'}}>
                      <div className="card-header">
                        <span className="card-subtitle">Feedback for Athlete</span>
                      </div>
                      <div className="card-body">
                        <TextField
                          id="outlined-multiline-flexible"
                          multiline
                          fullWidth
                          rows={12}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="section mt-2">
                    <div className="card" style={{height: '250px'}}>
                      <div className="card-header">
                        <span className="card-subtitle">Activity</span>
                      </div>
                      <div className="card-body" style={{display: 'flex', justifyContent: 'center'}}>
                        <div id="activity-heatmap"></div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="section mt-2">
                    <div className="card" style={{height: '250px'}}>
                      <div className="card-header">
                        <span className="card-subtitle">Load</span>
                      </div>
                      <div className="card-body" style={{display: 'flex', justifyContent: 'center'}}>
                        <div id="load-heatmap"></div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="section mt-2">
                    <div className="card" style={{height: '250px'}}>
                      <div className="card-header">
                        <span className="card-subtitle">RPE</span>
                      </div>
                      <div className="card-body" style={{display: 'flex', justifyContent: 'center'}}>
                        <div id="rpe-heatmap"></div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="section mt-2">
                      <div className="card">
                        <MUIDataTable
                          columns={columns}
                          data={cardData?.kpi}
                          options={{selectableRows: 'none', responsive: 'standard'}}
                          title="KPIs"
                        />
                      </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">Completed Exercise Tags</span>
                      </div>
                    <div style={{ width: '100%', height: 400 }}>
                      <ResponsiveContainer>
                        <BarChart
                          data={cardData?.tags}
                        >
                          <CartesianGrid />
                          <Tooltip />
                          <Bar dataKey="count" fill="#0B545E" />
                          <XAxis dataKey="name" />
                          <YAxis />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">WORKLOAD</span>
                      </div>
                    <div style={{ width: '100%', height: 400 }}>
                      <ResponsiveContainer>
                        <LineChart
                          data={cardData?.acwrChartData}
                        >
                          <CartesianGrid />
                          <Tooltip />
                          <Line connectNulls type="monotone" dataKey="acwr_strength" stroke="#0B545E" />
                          <Line connectNulls type="monotone" dataKey="acwr_distance" stroke="#36454F" />
                          <XAxis dataKey="name" />
                          <YAxis />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  </div>
                </Grid>
              <Grid item xs={12}>
                  <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">CALORIES BURNED</span>
                      </div>
                    <div style={{ width: '100%', height: 400 }}>
                      <ResponsiveContainer>
                        <BarChart
                          data={cardData?.calChartData}
                        >
                          <CartesianGrid />
                          <Tooltip />
                          <Bar dataKey="calories_burned" fill="#0B545E" />
                          <XAxis dataKey="name" />
                          <YAxis />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  </div>
              </Grid>
              <Grid item xs={12}>
                  <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">SLEEP</span>
                      </div>
                    <div style={{ width: '100%', height: 400 }}>
                      <ResponsiveContainer>
                        <BarChart
                          data={cardData?.sleepChartData}
                        >
                          <CartesianGrid />
                          <Tooltip />
                          <Bar dataKey="sleep_hours" fill="#0B545E" />
                          <XAxis dataKey="name" />
                          <YAxis />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  </div>
              </Grid>
              <Grid item xs={12}>
                  <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">HEART RATE</span>
                      </div>
                    <div style={{ width: '100%', height: 400 }}>
                      <ResponsiveContainer>
                        <LineChart
                          data={cardData?.hrChartData}
                        >
                          <CartesianGrid />
                          <Tooltip />
                          <Line connectNulls type="monotone" dataKey="heart_rate" stroke="#0B545E" />
                          <XAxis dataKey="name" />
                          <YAxis />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  </div>
              </Grid>
              <Grid item xs={12} style={{paddingBottom: '10%'}}>
                <DataTable />
              </Grid>
            </Grid>

      <Dialog onClose={() => setShowAcwrInfo(false)} open={showAcwrInfo}>
        <DialogContent>
          <DialogContentText>
            <strong style={{ fontWeight: 'bolder' }}>
              Acute-chronic workload ratio&nbsp;
            </strong>
            is a calculation of an athlete’s fitness and fatigue based on work
            done (ex. weight lifted, distance ran). The acute workload (most
            recent 7 days) is divided by the chronic workload (most recent 28
            days) to present a ratio. The ideal ratio is 0.8-1.3 skewing to
            either side increases fatigue and injury risk due to under/over
            training. The acute-chronic workload can help with understanding the
            preparedness of an athlete, their fatigue level and the relative
            injury risk of an athlete day-to-day.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => setShowSleepInfo(false)} open={showSleepInfo}>
        <DialogContent>
          <DialogContentText>
            <strong style={{ fontWeight: 'bolder' }}>
              The Sleep Score&nbsp;
            </strong>
            is the percentage of sleep as the Total Time Resting - Time Sleeping
            (sum of REM, Light, and Deep).
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => setShowRHRInfo(false)} open={showRHRInfo}>
        <DialogContent>
          <DialogContentText>
            <strong style={{ fontWeight: 'bolder' }}>
              Resting Heart Rate&nbsp;
            </strong>
            is measured when your Fitbit detects sleep, and by measuring it
            throughout the day while you are awake but inactive (i.e. no steps
            detected).
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={() => setShowCalBurnedInfo(false)}
        open={showCalBurnedInfo}
      >
        <DialogContent>
          <DialogContentText>
            <strong style={{ fontWeight: 'bolder' }}>
              Calories Burned&nbsp;
            </strong>
            total is the activity calories recorded from your Fitbit in either
            the exercise app or the Sidekick app.
          </DialogContentText>
        </DialogContent>
      </Dialog>


        </AthleteReport.Stylesheet>
    </ActivityLogContext.Provider>
    )
}

AthleteReport.Stylesheet = styled.div`

  .card-header {
    border-bottom: none;
    padding: 16px 16px;
  }

  .subtitle {
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .section{
    padding: 0 4px;
    margin-top: 6px !important;
  }

  .MuiGrid-container {
    height: 200px;
  }

  .card-body{
    padding: 0px 12px;
  }

  .MuiIconButton-root{
    padding 3px;
  }

  .card{
    min-height: 164px;
  }

  footer{
    padding 10px 10px;
  }

  h2{
    display: inline;
  }

  .MuiTableCell-root{
    padding: 1px;
    text-align: center;
  }
  .MuiTableCell-head{
    background-color: #0b545e;
    color: white;
    text-align: center;
  }
  .MuiTableRow-root:nth-child(even){
    background-color: #dedede;
  }
  .MUIDataTableHeadCell-toolButton-59{
    color: white;
    text-align: center;
    margin: 0px 0px;
  }
  .MUIDataTableHeadCell-contentWrapper-60{
    justify-content: center;
  }
  .MuiButton-text {
    color: white;
  }
  .jss60 {
    display: flex;
    align-items: center;
    justify-content: center;
  }


`

export default AthleteReport
