import React, {useEffect, useContext} from 'react'
import Layout from 'common/components/Layout'
import PageTitle from 'common/components/PageTitle'
import useApi from 'common/hooks/useApi'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {TabContext, TabPanel} from '@material-ui/lab';
import { useParams } from "react-router-dom";
import { UserContext } from 'account'

function Pricing() {
  const user = useContext(UserContext)
  const routeParams = useParams();
  const [{ loading: plansLoading, data: plansData = {} }] = useApi('plans/')
  const [{ loading: subscriptionsLoading, data: subscriptionsData = {} }] =
    useApi('subscriptions/')
  const [{ loading: createSubscriptionLoading }, createSubscription] = useApi(
    { method: 'POST' },
    { manual: true }
  )
  const [{ loading: loadingPortal }, getCustomerPortalUrl] = useApi(
    { method: 'GET' },
    { manual: true }
  )

  const isLoading =
    plansLoading || subscriptionsLoading || createSubscriptionLoading || loadingPortal

  const { subscriptions } = subscriptionsData

  const plans = plansData.plans?.filter(
    (plan) => plan.active
  )

  function getCallToAction(plan, variant = '') {
    const subscription = subscriptions?.find((s) => s.stripe_product_id === plan.id)

    if ((subscription?.status === 'active' || subscription?.status === 'trialing') && variant === 'current') {
      return true
    }

    if (
      (subscription?.status === 'unpaid' || subscription?.status === 'past_due' || subscription?.status === 'canceled' )
      && variant === 'renew'
      ) {
      return true
    }

    if (
      !['active', 'trialing', 'unpaid', 'past_due', 'canceled'].includes(subscription?.status) &&
      variant === 'subscribe'
    ) {
      return true
    }

    return false
  }

  async function handleSubscribed(plan) {
      const { data } = await createSubscription({
        url:'stripe/checkout_url/',
        data: {
          momentum_id: plan?.metadata?.momentum_id,
          program_id: plan?.metadata?.program_id,
          trainers: plan?.metadata?.trainers,
          price: plan?.default_price?.id,
          trial_days: parseInt(plan?.metadata?.trial_days, 10) || 0
        },
      })

      window.location = data?.checkoutUrl
  }

  async function goToSubscriptionPage() {
    const portalUrl = await getCustomerPortalUrl({
      url:'stripe/customer_portal_url/',
    })
    if(portalUrl.data.customerPortalUrl) {
      window.location.href = portalUrl.data.customerPortalUrl
    }
  }
  const [value, setValue] = React.useState(routeParams.id)
  const [list, setList] = React.useState(plans)

  useEffect(() => {
    const startList = plansData.plans?.filter(
    (plan) => plan.status === 'active' && plan.enabled_in_hosted_pages
    )
    setList(startList?.filter(
                (plan) => !plan.metadata?.momentum_id?.includes('tylor')
                && !plan.metadata?.momentum_id?.includes('martha')
                && !plan.metadata?.momentum_id?.includes('coach-g')
                && !plan.metadata?.momentum_id?.includes('coach-stine')
                && !plan.metadata?.momentum_id?.includes('coach-terrance')
                && !plan.metadata?.momentum_id?.includes('coach-dustin')
                && !plan.metadata?.momentum_id?.includes('coach-justin')
                && !plan.metadata?.momentum_id?.includes('coach-greyno')
                && !plan.metadata.momentum_id?.includes('trainer')
            ))

    let filteredList = []
    switch (routeParams.id) {
        case 'general':
            filteredList = plans?.filter(
                    (plan) => !plan.metadata?.momentum_id?.includes('tylor')
                    && !plan.metadata?.momentum_id?.includes('martha')
                    && !plan.metadata?.momentum_id?.includes('coach-g')
                    && !plan.metadata?.momentum_id?.includes('coach-stine')
                    && !plan.metadata?.momentum_id?.includes('coach-terrance')
                    && !plan.metadata?.momentum_id?.includes('coach-dustin')
                    && !plan.metadata?.momentum_id?.includes('coach-justin')
                    && !plan.metadata?.momentum_id?.includes('coach-greyno')
                    && !plan.metadata?.momentum_id?.includes('trainer')
                )
            setList(filteredList)
            break;
        case 'coach-henry':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('tylor')
                )
            setList(filteredList)
            break;
        case 'mcw':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('martha')
                )
            setList(filteredList)
            break;
        case 'coach-g':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('coach-g')
                )
            setList(filteredList)
            break;
        case 'coach-stine':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('coach-stine')
                )
            setList(filteredList)
            break;
        case 'coach-terrance':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('coach-terrance')
                )
            setList(filteredList)
            break;
        case 'coach-dustin':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('coach-dustin')
                )
            setList(filteredList)
            break;
        case 'coach-justin':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('coach-justin')
                )
            setList(filteredList)
            break;
        case 'coach-greyno':
            filteredList = plans?.filter(
                    (plan) => plan.metadata?.momentum_id?.includes('coach-greyno')
                )
            setList(filteredList)
            break;
        case 'trainer':
            filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('trainer')
            )
            setList(filteredList)
            break;
        default:
            filteredList = plans?.filter(
                    (plan) => !plan.metadata?.momentum_id?.includes('tylor')
                    && !plan.metadata?.momentum_id?.includes('martha')
                    && !plan.metadata?.momentum_id?.includes('coach-g')
                    && !plan.metadata?.momentum_id?.includes('coach-stine')
                    && !plan.metadata?.momentum_id?.includes('coach-terrance')
                    && !plan.metadata?.momentum_id?.includes('coach-dustin')
                    && !plan.metadata?.momentum_id?.includes('coach-justin')
                    && !plan.metadata?.momentum_id?.includes('coach-greyno')
                    && !plan.metadata?.momentum_id?.includes('trainer')
                )
            setList(filteredList)
            break;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const idToSubscribeTo = urlParams.get('subscribe_to')

    if(idToSubscribeTo){
      const planToSubscribeTo = plansData.plans?.filter(
        (plan) => plan.id === idToSubscribeTo
      )[0];
      if(planToSubscribeTo) {
        if(subscriptionsData){
          goToSubscriptionPage()
        }
        handleSubscribed(planToSubscribeTo);
        urlParams.set('subscribe_to', '');
      }
    }

  }, [plansData, subscriptions]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    let filteredList = []
    switch (newValue) {
    case 'general':
        filteredList = plans?.filter(
                (plan) => !plan.metadata.momentum_id?.includes('tylor')
                && !plan.metadata.momentum_id?.includes('martha')
                && !plan.metadata?.momentum_id?.includes('coach-g')
                && !plan.metadata?.momentum_id?.includes('coach-stine')
                && !plan.metadata?.momentum_id?.includes('coach-terrance')
                && !plan.metadata?.momentum_id?.includes('coach-dustin')
                && !plan.metadata?.momentum_id?.includes('coach-justin')
                && !plan.metadata?.momentum_id?.includes('coach-greyno')
                && !plan.metadata.momentum_id?.includes('trainer')
            )
        setList(filteredList)
        break;
    case 'coach-henry':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('tylor')
            )
        setList(filteredList)
        break;
    case 'mcw':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('martha')
            )
        setList(filteredList)
        break;
    case 'coach-g':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('coach-g')
            )
        setList(filteredList)
        break;
    case 'coach-stine':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('coach-stine')
            )
        setList(filteredList)
        break;
    case 'coach-terrance':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('coach-terrance')
            )
        setList(filteredList)
        break;
    case 'coach-dustin':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('coach-dustin')
            )
        setList(filteredList)
        break;
    case 'coach-justin':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('coach-justin')
            )
        setList(filteredList)
        break;
    case 'coach-greyno':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('coach-greyno')
            )
        setList(filteredList)
        break;
    case 'trainer':
        filteredList = plans?.filter(
                (plan) => plan.metadata?.momentum_id?.includes('trainer')
            )
        setList(filteredList)
        break;
    default:
        filteredList = plans?.filter(
                (plan) => !plan.metadata?.momentum_id?.includes('tylor')
                && !plan.metadata?.momentum_id?.includes('martha')
                && !plan.metadata?.momentum_id?.includes('coach-g')
                && !plan.metadata?.momentum_id?.includes('coach-stine')
                && !plan.metadata?.momentum_id?.includes('coach-terrance')
                && !plan.metadata?.momentum_id?.includes('coach-dustin')
                && !plan.metadata?.momentum_id?.includes('coach-justin')
                && !plan.metadata?.momentum_id?.includes('coach-greyno')
                && !plan.metadata?.momentum_id?.includes('trainer')
            )
        setList(filteredList)
        break;
    }
  }

  return (
    <Layout>
      <PageTitle title="Plans" />

      <div className="section">
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            variant="scrollable"
            scrollButtons="on"
            allowScrollButtonsMobile
          >
            <Tab value="general" label="General"/>
            <Tab value="coach-henry" label="Tylor Henry"/>
            <Tab value="mcw" label="Martha Wilkins"/>
            <Tab value="coach-g" label="G Humphrey"/>
            <Tab value="coach-stine" label="Stine Emrick"/>
            <Tab value="coach-terrance" label="Terrance Dandridge"/>
            <Tab value="coach-dustin" label="Dustin Whitt"/>
            <Tab value="coach-justin" label="Justin Segura"/>
            <Tab value="coach-greyno" label="Nick Greyno"/>
            <Tab value="trainer" label="Trainers"/>
          </Tabs>
          <TabPanel value='general' index={0}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name || ''}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          type='button'
                          onClick={() => goToSubscriptionPage()}
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-henry' index={1}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name || ''}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          type="button"
                          onClick={() => goToSubscriptionPage()}
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='mcw' index={2}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-g' index={3}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-stine' index={4}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-terrance' index={5}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-dustin' index={6}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-justin' index={7}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='coach-greyno' index={8}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>

                      <span className="display-4">
                        {Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan.default_price?.unit_amount || 0) / 100)}
                      </span>
                      / mo
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => goToSubscriptionPage()}
                          type="button"
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value='trainer' index={9}>
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-around " style={{ gap: '2rem' }}>
              {list?.map((plan) => (

                <div key={plan.id} className="card text-center">
                  <div className="card-body d-flex flex-column align-items-center">
                    <div className="mb-4">
                      <h5>{plan.name}</h5>
                        Starting at <br />
                        {plan?.default_price?.tiers ? plan?.default_price?.tiers?.map((tier) => (
                            Intl.NumberFormat('en-US', {
                              currency: 'USD',
                              style: 'currency',
                            }).format(tier.unit_amount / 100)
                            ))[0]
                         : Intl.NumberFormat('en-US', {
                          currency: 'USD',
                          style: 'currency',
                        }).format((plan?.default_price?.unit_amount || 0) / 100)
                      }
                      /athlete
                    </div>

                    {!!plan.description && (
                      <>
                        <h6>Includes:</h6>
                        <pre className='text-wrap text-break plan-description'>{plan.description}</pre>
                      </>
                    )}

                    <div className="mt-auto">
                      {!isLoading && getCallToAction(plan, 'current') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          disabled
                          type="button"
                        >
                          Your Plan
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'renew') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          type="button"
                          onClick={() => goToSubscriptionPage()}
                        >
                          Renew
                        </button>
                      )}
                      {!isLoading && getCallToAction(plan, 'subscribe') && (
                        <button
                          className="btn btn-lg btn-outline-primary"
                          onClick={() => handleSubscribed(plan)}
                          type="button"
                          disabled={user?.hasActiveStripeSubscription || user?.hasInTrialSubscription}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </Layout>
  )
}

export default Pricing
